import React from "react";
import { ExternalLinkImage } from "../../assets/images/ExternalLinkImage";
import "./style.css";

export const ExternalLink = (): JSX.Element => {
  return (
    <div className="external-link">
      <ExternalLinkImage className="external-image"/>
    </div>
  );
};
