import React from "react";
import { ExternalLink } from "../../components/ExternalLink";
import { AwsPartner, AwsQualifiedSoftware, MicrosoftPartner, LinkedIn, Twitter,  Youtube} from "../../assets/images";
import { Logo } from "../../assets/icons/Logo";
import "./style.css";

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="content ">
        <div className="content-left">
          <Logo className="logo-1" />
          <div className="images-container partner-images grid grid-cols-2 sm:grid-cols-4">
            <div className="prtnrImage">
              <AwsPartner className="img"/>
            </div>
            <div className="prtnrImage">
              <AwsQualifiedSoftware className="img"/>
            </div>
            <div className="prtnrImage">
              <img
                className="image"
                alt="Google Partner"
                src="GooglePartner.png"
              />
            </div>
            <div className="prtnrImage">
              <MicrosoftPartner className="layer"/>
            </div>
          </div>
        </div>
        <div className="content-right">
          <div className="social-media-frame">
            <a href="https://www.linkedin.com/company/kivera" target="_blank" rel="noopener noreferrer"><LinkedIn className="social-media-icon"/></a>
            <a href="https://twitter.com/kivera_io" target="_blank" rel="noopener noreferrer"><Twitter  className="social-media-icon"/></a>
            <a href="https://www.youtube.com/@kivera907" target="_blank" rel="noopener noreferrer"><Youtube /></a>
          </div>
          <div className="images-container footer-links">
            <div className="text-wrapper-4">Patent Pending: No. 62/984,725</div>
            <div className="content-right-links">
              <div className="text-wrapper-4">
                <a href="https://www.kivera.io/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </div>
              <ExternalLink />
            </div>
            <div className="content-right-links">
              <div className="text-wrapper-4">
                <a href="https://www.kivera.io/" target="_blank" rel="noopener noreferrer">
                  Kivera Website
                </a>
              </div>
              <ExternalLink />
            </div>
          </div>
          <p className="made-with-by-raw">
            <span className="span">Made with 🤍 by  Kivera</span>
          </p>
        </div>
      </div>
    </footer>
  );
};
