import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconArrow5 = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-arrow-5 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M17.3536 11.1464C17.5488 11.3417 17.5488 11.6583 17.3536 11.8536L14.1716 15.0355C13.9763 15.2308 13.6597 15.2308 13.4645 15.0355C13.2692 14.8403 13.2692 14.5237 13.4645 14.3284L15.7929 12L7 12C6.72386 12 6.5 11.7761 6.5 11.5C6.5 11.2239 6.72386 11 7 11L15.7929 11L13.4645 8.67157C13.2692 8.47631 13.2692 8.15973 13.4645 7.96447C13.6597 7.7692 13.9763 7.7692 14.1716 7.96447L17.3536 11.1464Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconArrow5.propTypes = {
  color: PropTypes.string,
};
