import React from "react";

interface Props {
  className?: any;
}

export const EnforcementMode = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1099_1437)">
        <path
          d="M24.2544 38C34.1592 33.8908 35.9275 27.1693 35.9275 23.0601C35.9275 19.0449 35.9275 15.0076 35.9275 15.0076L24.2544 10.4091L12.5813 15.0076C12.5813 15.0076 12.5813 19.0466 12.5813 23.0601C12.5813 27.1693 14.3496 33.8908 24.2544 38Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M24.0188 17L26.1066 21.1031L30.7723 21.7625L27.3955 24.9568L28.1924 29.4679L24.0188 27.339L19.8452 29.4679L20.6421 24.9568L17.2654 21.7625L21.931 21.1031L24.0188 17Z"
          fill="white"
        />
        <path
          d="M14.0976 29.6082C13.7186 31.9745 12.2451 34.0162 7.69467 36.0129C2.16642 33.5871 1.17944 30.6191 1.17944 28.1932V23.4395L7.69467 20.7249L12.4689 22.7141V22.8114C12.4689 24.6994 12.8217 27.1263 14.0976 29.6082Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M7.7441 25L8.75937 26.9953L11.0282 27.3159L9.38617 28.8693L9.77368 31.063L7.7441 30.0278L5.71452 31.063L6.10203 28.8693L4.45996 27.3159L6.72883 26.9953L7.7441 25Z"
          fill="white"
        />
        <path
          d="M34.3023 29.6082C34.6813 31.9745 36.1548 34.0162 40.7052 36.0129C46.2335 33.5871 47.2205 30.6191 47.2205 28.1932V23.4395L40.7052 20.7249L35.931 22.7141V22.8114C35.931 24.6994 35.5782 27.1263 34.3023 29.6082Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M40.6558 25L39.6405 26.9953L37.3717 27.3159L39.0137 28.8693L38.6262 31.063L40.6558 30.0278L42.6854 31.063L42.2979 28.8693L43.9399 27.3159L41.6711 26.9953L40.6558 25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1099_1437">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
