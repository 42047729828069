import React from "react";
interface Props {
  className?: any;
}

export const MicrosoftPartner = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="71"
      height="32"
      viewBox="0 0 71 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_628_21105)">
        <path
          d="M13.2303 13.744H11.7099V5.30357C11.7099 4.63676 11.7535 3.82799 11.8362 2.86865H11.8014C11.6576 3.43651 11.5269 3.8409 11.4137 4.08611L6.99196 13.744H6.25138L1.8166 4.15494C1.69463 3.89682 1.56393 3.47093 1.42889 2.86865H1.39404C1.44196 3.37198 1.46809 4.18936 1.46809 5.32078V13.7397H0V1.17798H2.00828L5.98564 9.94109C6.28623 10.6079 6.48227 11.1112 6.57375 11.4468H6.6391C6.93969 10.6423 7.14879 10.1261 7.26206 9.90668L11.3222 1.17798H13.2303V13.744Z"
          fill="white"
        />
        <path
          d="M17.452 13.744H15.9708V4.77006H17.452V13.744ZM17.7047 1.55648C17.7047 1.82751 17.6088 2.05121 17.4171 2.22759C17.2255 2.40397 16.9946 2.49431 16.7288 2.49431C16.4631 2.49431 16.2278 2.40827 16.0405 2.2362C15.8532 2.06412 15.7617 1.83611 15.7617 1.55648C15.7617 1.30697 15.8532 1.08757 16.0362 0.902584C16.2191 0.7176 16.45 0.627258 16.7288 0.627258C17.0076 0.627258 17.2385 0.7176 17.4258 0.898282C17.6132 1.07897 17.7047 1.29837 17.7047 1.55648Z"
          fill="white"
        />
        <path
          d="M26.2331 13.331C25.5143 13.7526 24.6692 13.9634 23.6933 13.9634C22.8482 13.9634 22.0902 13.7741 21.4193 13.3998C20.7484 13.0212 20.2257 12.4921 19.851 11.7995C19.4764 11.1112 19.2891 10.3368 19.2891 9.47642C19.2891 7.99224 19.7291 6.80059 20.6047 5.90148C21.4803 5.00237 22.6478 4.55066 24.1072 4.55066C24.9175 4.55066 25.6319 4.70123 26.2505 5.00667V6.47794C25.5579 6.00903 24.8216 5.77672 24.0419 5.77672C23.0835 5.77672 22.3037 6.10797 21.7025 6.77048C21.1013 7.43298 20.8007 8.28907 20.8007 9.34305C20.8007 10.397 21.0839 11.2144 21.6546 11.821C22.2253 12.4276 22.9876 12.733 23.946 12.733C24.7563 12.733 25.5143 12.4749 26.2244 11.963V13.331"
          fill="white"
        />
        <path
          d="M32.8425 6.22417C32.5767 6.03058 32.2021 5.93594 31.7098 5.93594C31.052 5.93594 30.5205 6.23708 30.111 6.83505C29.7015 7.43303 29.4968 8.21168 29.4968 9.17102V13.744H28.0156V4.77011H29.4968V6.61995H29.5316C29.7364 6.00047 30.0544 5.51004 30.4813 5.15298C30.9083 4.79162 31.4005 4.61523 31.9538 4.61523C32.3415 4.61523 32.6334 4.65825 32.8381 4.73999V6.22847"
          fill="white"
        />
        <path
          d="M40.6444 9.27422C40.6444 8.14711 40.3873 7.28241 39.8733 6.68013C39.3592 6.07786 38.6187 5.77672 37.6603 5.77672C36.7019 5.77672 35.9526 6.08646 35.3993 6.70164C34.8461 7.31683 34.5672 8.18582 34.5672 9.30864C34.5672 10.4315 34.8461 11.223 35.4037 11.8296C35.9613 12.4362 36.7149 12.7373 37.6603 12.7373C38.6056 12.7373 39.3636 12.4405 39.8777 11.8425C40.3917 11.2488 40.6444 10.3927 40.6444 9.27422ZM42.1647 9.2226C42.1647 10.6466 41.7465 11.7952 40.9101 12.6599C40.0737 13.5289 38.9541 13.9591 37.5514 13.9591C36.1486 13.9591 35.09 13.5375 34.271 12.6943C33.452 11.8511 33.0469 10.7369 33.0469 9.36026C33.0469 7.87608 33.4651 6.70595 34.3059 5.84125C35.1423 4.98086 36.2967 4.55066 37.7692 4.55066C39.1458 4.55066 40.2218 4.96795 40.9972 5.79823C41.777 6.62851 42.1647 7.77284 42.1647 9.2183"
          fill="white"
        />
        <path
          d="M49.0566 11.3435C49.0566 12.1222 48.7473 12.7502 48.1287 13.2364C47.5101 13.7225 46.6867 13.9634 45.663 13.9634C44.7874 13.9634 44.0206 13.7827 43.3672 13.4214V11.8812C44.0947 12.4534 44.8963 12.7416 45.7719 12.7416C46.9481 12.7416 47.5362 12.3244 47.5362 11.4898C47.5362 11.1499 47.423 10.8746 47.1921 10.6638C46.9612 10.453 46.4428 10.1648 45.6281 9.79909C44.8091 9.45924 44.2297 9.09787 43.8899 8.70639C43.5502 8.31922 43.3846 7.80298 43.3846 7.15769C43.3846 6.41775 43.6939 5.79826 44.3082 5.29923C44.9224 4.80451 45.7022 4.55499 46.6519 4.55499C47.3794 4.55499 48.0416 4.69696 48.634 4.97659V6.42205C48.0285 5.99615 47.3315 5.78105 46.5343 5.78105C46.0463 5.78105 45.6543 5.89721 45.3537 6.12951C45.0531 6.36182 44.905 6.66296 44.905 7.03293C44.905 7.42871 45.0182 7.73415 45.2491 7.94925C45.4757 8.16435 45.9505 8.41386 46.6693 8.70639C47.5493 9.06776 48.1679 9.45064 48.5251 9.84642C48.8823 10.2422 49.0566 10.7412 49.0566 11.3435Z"
          fill="white"
        />
        <path
          d="M57.6565 9.27422C57.6565 8.14711 57.3995 7.28241 56.8811 6.68013C56.3671 6.07786 55.6265 5.77672 54.6681 5.77672C53.7097 5.77672 52.9604 6.08646 52.4071 6.70164C51.8539 7.31683 51.5751 8.18582 51.5751 9.30864C51.5751 10.4315 51.8539 11.223 52.4115 11.8296C52.9691 12.4362 53.7227 12.7373 54.6681 12.7373C55.6134 12.7373 56.3714 12.4405 56.8855 11.8425C57.3995 11.2488 57.6522 10.3927 57.6522 9.27422M59.1726 9.2226C59.1726 10.6466 58.7543 11.7952 57.9179 12.6599C57.0815 13.5289 55.9619 13.9591 54.5592 13.9591C53.1564 13.9591 52.0978 13.5375 51.2788 12.6943C50.4598 11.8511 50.0547 10.7369 50.0547 9.36026C50.0547 7.87608 50.4729 6.70595 51.3137 5.84125C52.1501 4.98086 53.3045 4.55066 54.777 4.55066C56.1536 4.55066 57.2296 4.96795 58.005 5.79823C58.7805 6.62851 59.1726 7.77284 59.1726 9.2183"
          fill="white"
        />
        <path
          d="M65.2498 1.72C64.9623 1.56082 64.6312 1.48339 64.2653 1.48339C63.2285 1.48339 62.7101 2.11578 62.7101 3.38486V4.77009H64.8795V5.98755H62.7101V13.744H61.2289V5.98755H59.6562V4.77009H61.2289V3.31603C61.2289 2.40401 61.5077 1.66837 62.061 1.10912C62.6142 0.54986 63.32 0.26593 64.1782 0.26593C64.6312 0.26593 64.9884 0.317554 65.2542 0.425103V1.72"
          fill="white"
        />
        <path
          d="M70.6387 13.6537C70.2814 13.8473 69.8197 13.9419 69.2446 13.9419C67.6284 13.9419 66.8225 13.06 66.8225 11.2962V5.98756H65.2324V4.7701H66.8225V2.5804L68.3037 2.11578V4.7701H70.6387V5.98756H68.3037V11.051C68.3037 11.6533 68.4082 12.0791 68.6217 12.333C68.8351 12.5868 69.188 12.7158 69.6803 12.7158C70.0462 12.7158 70.3686 12.6212 70.6387 12.4276V13.6537Z"
          fill="white"
        />
        <path
          d="M23.0757 22.3093C23.0757 20.6444 22.0607 19.8141 20.035 19.8141H18.2532V24.9679H19.8433C20.8888 24.9679 21.6861 24.7399 22.2393 24.2796C22.7926 23.8235 23.0714 23.1653 23.0714 22.3136M24.6615 22.2361C24.6615 23.4536 24.2302 24.4301 23.3633 25.1744C22.4963 25.9186 21.3637 26.2929 19.9566 26.2929H18.2489V31.0509H16.7285V18.4848H20.292C21.6773 18.4848 22.7534 18.8118 23.5157 19.47C24.2781 20.1282 24.6615 21.0488 24.6615 22.2361Z"
          fill="white"
        />
        <path
          d="M30.9254 26.508L28.6819 26.8134C27.9152 26.9167 27.3793 27.0973 27.07 27.3426C26.7607 27.5921 26.6083 27.9922 26.6083 28.5471C26.6083 28.9988 26.7738 29.3559 27.1049 29.6312C27.436 29.9022 27.8629 30.0399 28.3813 30.0399C29.1175 30.0399 29.7231 29.7904 30.2023 29.287C30.6815 28.7837 30.9211 28.1556 30.9211 27.4028V26.508M32.4022 31.0466H30.9211V29.6441H30.8862C30.2415 30.7239 29.2918 31.266 28.0371 31.266C27.1441 31.266 26.4296 31.0293 25.8938 30.5604C25.358 30.0915 25.0879 29.4591 25.0879 28.6633C25.0879 26.9941 26.1073 26.0175 28.1461 25.7379L30.9211 25.3593C30.9211 23.8408 30.285 23.0793 29.013 23.0793C27.8977 23.0793 26.8914 23.445 25.994 24.1763V22.705C26.2641 22.5071 26.7302 22.3136 27.388 22.1329C28.0459 21.9479 28.6296 21.8575 29.1437 21.8575C31.3218 21.8575 32.4066 22.9761 32.4066 25.2131V31.0509"
          fill="white"
        />
        <path
          d="M39.569 23.531C39.3033 23.3374 38.9287 23.2428 38.4364 23.2428C37.7786 23.2428 37.2471 23.5439 36.8376 24.1419C36.4281 24.7398 36.2234 25.5185 36.2234 26.4778V31.0508H34.7422V22.0769H36.2234V23.9268H36.2582C36.463 23.3073 36.781 22.8169 37.2079 22.4598C37.6348 22.0984 38.1271 21.9221 38.6803 21.9221C39.0681 21.9221 39.3599 21.9651 39.5647 22.0468V23.5353"
          fill="white"
        />
        <path
          d="M45.5547 30.9605C45.1975 31.1541 44.7357 31.2487 44.1606 31.2487C42.5444 31.2487 41.7385 30.3668 41.7385 28.603V23.2944H40.1484V22.0769H41.7385V19.8872L43.2197 19.4226V22.0769H45.5547V23.2944H43.2197V28.3578C43.2197 28.9601 43.3242 29.386 43.5377 29.6398C43.7468 29.8936 44.0997 30.0227 44.5963 30.0227C44.9622 30.0227 45.2846 29.928 45.5547 29.7344V30.9605Z"
          fill="white"
        />
        <path
          d="M54.7584 31.0465H53.2772V25.9315C53.2772 24.0343 52.5584 23.0836 51.1252 23.0836C50.3977 23.0836 49.7834 23.3503 49.2955 23.8837C48.8032 24.4172 48.5593 25.1012 48.5593 25.9315V31.0465H47.0781V22.0726H48.5593V23.5611H48.5941C49.2868 22.4211 50.3018 21.8532 51.6349 21.8532C52.6543 21.8532 53.4297 22.1759 53.9655 22.8169C54.497 23.4578 54.7671 24.3699 54.7671 25.5486V31.0422"
          fill="white"
        />
        <path
          d="M62.945 25.7035C62.9319 24.8732 62.7272 24.2322 62.3264 23.7719C61.9256 23.3116 61.3593 23.0836 60.6318 23.0836C59.9435 23.0836 59.3554 23.3245 58.8674 23.802C58.3795 24.2795 58.0702 24.9162 57.9439 25.7035H62.945ZM64.4654 26.9209H57.9265C57.9482 27.9319 58.2271 28.7019 58.7542 29.2397C59.2813 29.7731 60.0132 30.0399 60.9454 30.0399C61.9953 30.0399 62.9494 29.7129 63.8119 29.059V30.4012C63.0016 30.9777 61.93 31.2702 60.5926 31.2702C59.2552 31.2702 58.2401 30.8572 57.4995 30.0313C56.759 29.2053 56.3887 28.061 56.3887 26.6026C56.3887 25.7164 56.576 24.9033 56.9463 24.172C57.3166 23.4406 57.8263 22.8728 58.4797 22.4684C59.1288 22.064 59.8563 21.8618 60.6492 21.8618C61.8472 21.8618 62.7838 22.2404 63.459 22.9975C64.1299 23.7547 64.4654 24.813 64.4654 26.1724V26.9252"
          fill="white"
        />
        <path
          d="M70.9992 23.531C70.7334 23.3374 70.3588 23.2428 69.8665 23.2428C69.2087 23.2428 68.6772 23.5439 68.2677 24.1419C67.8582 24.7398 67.6535 25.5185 67.6535 26.4778V31.0508H66.168V22.0769H67.6535V23.9268H67.6883C67.8931 23.3073 68.2111 22.8169 68.638 22.4598C69.0649 22.0984 69.5572 21.9221 70.1105 21.9221C70.4982 21.9221 70.7944 21.9651 70.9948 22.0468V23.5353"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_628_21105">
          <rect
            width="71"
            height="31"
            fill="white"
            transform="translate(0 0.26593)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
