import React from "react";

interface Props {
  className?: any;
}

export const ExternalLinkImage = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.96879 1.5H9.76168L3.61523 7.64645L4.32234 8.35355L10.4688 2.20711V5H11.4688V0.5H6.96879V1.5ZM8.46875 10.5V6L9.46875 5V11.5H0.46875V2.5H6.96875L5.96875 3.5H1.46875V10.5H8.46875Z"
        fill="url(#paint0_linear_216_3203)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_216_3203"
          x1="6.12959"
          y1="1.82011e-08"
          x2="6.12959"
          y2="11.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
