import React from "react";
import { PrimaryButton } from "../PrimaryButton";
import "./style.css";

interface Props {
  className: any;
}

export const CtaBanner = ({ className }: Props): JSX.Element => {
  return (
    <div className={`CTA-banner ${className}`}>
      <div className="frame">
        <p className="div">Explore the platform and find out more.</p>
        <PrimaryButton className="primary-button-instance" inverse={false} stateProp="default" text="Request Demo" onClick={() => window.open('https://www.kivera.io/request-demo','_blank')}/>
      </div>
    </div>
  );
};
