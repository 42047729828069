import React from "react";

interface Props {
  className?: any;
}

export const PolicyLanguage = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83632 28.8338H7V19.1662H10.7147C11.6679 19.1662 12.3968 19.434 12.9015 19.9695C13.4061 20.5051 13.6584 21.2345 13.6584 22.1579C13.6584 22.8689 13.4902 23.4691 13.1538 23.9584C12.8267 24.4386 12.3548 24.7433 11.738 24.8726L13.8266 28.8338H11.8081L9.94372 25.0803H8.83632V28.8338ZM10.3362 23.6676C10.8315 23.6676 11.1866 23.5706 11.4016 23.3767C11.6165 23.1736 11.724 22.855 11.724 22.4211V21.8947C11.724 21.4608 11.6165 21.1468 11.4016 20.9529C11.1866 20.7498 10.8315 20.6482 10.3362 20.6482H8.83632V23.6676H10.3362Z"
        fill="white"
      />
      <path
        d="M15.4472 28.8338V19.1662H21.629V20.662H17.2835V23.1828H21.4748V24.6787H17.2835V27.338H21.629V28.8338H15.4472Z"
        fill="white"
      />
      <path
        d="M28.5903 27.4903H28.4782C28.3941 27.6842 28.2913 27.8735 28.1698 28.0582C28.0483 28.2428 27.8988 28.4044 27.7212 28.5429C27.5437 28.6814 27.3334 28.7922 27.0904 28.8753C26.8568 28.9584 26.5858 29 26.2774 29C25.2868 29 24.5345 28.5706 24.0206 27.7119C23.5159 26.844 23.2636 25.6297 23.2636 24.0693C23.2636 22.4257 23.5626 21.1699 24.1607 20.3019C24.7588 19.434 25.6793 19 26.9222 19C27.4082 19 27.8334 19.0646 28.1978 19.1939C28.5716 19.3232 28.8894 19.5032 29.151 19.7341C29.422 19.9649 29.6417 20.2419 29.8099 20.5651C29.9874 20.879 30.1276 21.2207 30.2304 21.59L28.5483 22.0609C28.4829 21.8578 28.4081 21.6593 28.324 21.4654C28.2492 21.2715 28.1464 21.1053 28.0156 20.9668C27.8941 20.819 27.7446 20.7036 27.567 20.6205C27.3895 20.5282 27.1699 20.482 26.9082 20.482C26.3008 20.482 25.8662 20.7221 25.6046 21.2022C25.3429 21.6824 25.2121 22.338 25.2121 23.169V24.7202C25.2121 25.1357 25.2401 25.5189 25.2962 25.8698C25.3616 26.2114 25.4644 26.5069 25.6046 26.7562C25.7447 27.0055 25.927 27.1994 26.1512 27.338C26.3755 27.4672 26.6559 27.5319 26.9923 27.5319C27.5156 27.5319 27.9128 27.3795 28.1838 27.0748C28.4548 26.7608 28.5903 26.3638 28.5903 25.8837V25.0942H26.8101V23.7784H30.2304V28.8338H28.5903V27.4903Z"
        fill="white"
      />
      <path
        d="M35.2993 29C34.6639 29 34.1125 28.8892 33.6452 28.6676C33.178 28.4367 32.7902 28.109 32.4818 27.6842C32.1827 27.2595 31.9584 26.7378 31.8089 26.1191C31.6687 25.5005 31.5987 24.7941 31.5987 24C31.5987 23.2151 31.6687 22.5134 31.8089 21.8947C31.9584 21.2669 32.1827 20.7405 32.4818 20.3158C32.7902 19.891 33.178 19.5679 33.6452 19.3463C34.1125 19.1154 34.6639 19 35.2993 19C36.5703 19 37.5048 19.4386 38.1029 20.3158C38.701 21.193 39 22.4211 39 24C39 25.5789 38.701 26.807 38.1029 27.6842C37.5048 28.5614 36.5703 29 35.2993 29ZM35.2993 27.518C35.6264 27.518 35.9021 27.458 36.1264 27.338C36.3507 27.2087 36.5282 27.0286 36.659 26.7978C36.7992 26.5669 36.8973 26.2853 36.9534 25.9529C37.0188 25.6113 37.0515 25.2327 37.0515 24.8172V23.169C37.0515 22.338 36.9207 21.6824 36.659 21.2022C36.4067 20.7221 35.9535 20.482 35.2993 20.482C34.6452 20.482 34.1873 20.7221 33.9256 21.2022C33.6733 21.6824 33.5471 22.338 33.5471 23.169V24.831C33.5471 25.6621 33.6733 26.3176 33.9256 26.7978C34.1873 27.2779 34.6452 27.518 35.2993 27.518Z"
        fill="white"
      />
      <rect x="0.5" y="13.5" width="46" height="21" stroke="white" />
    </svg>
  );
};
