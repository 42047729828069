import { Logger } from "aws-sdk/lib/config-base";

export enum LOG_MODE {
    CONSOLE = 'CONSOLE',
    OUTPUT = 'OUTPUT'
}

export class LogHandler implements Logger {
    private readonly defaultLogMode: LOG_MODE = LOG_MODE.CONSOLE

    constructor(logMode?: LOG_MODE) {
        if(logMode) {
            this.defaultLogMode = logMode
        }
    }
    setLocalStorageInfo = (logData:any) => {
        const getResultsLog = localStorage.getItem('resultsLog')
        if(getResultsLog){
            const itemsList = JSON.parse(getResultsLog)
            itemsList.push({data: logData})
            localStorage.setItem('resultsLog', JSON.stringify(itemsList))
        }else{
            const itemsListData: any[] = [{data: logData}]
            localStorage.setItem('resultsLog', JSON.stringify(itemsListData))
        }
    }

    write(chunk: any, encoding: string | undefined, callback: (() => void) | undefined): void {
        console.log('WRITING LOG')
    }

    log(messages: any): void {
        this.setLocalStorageInfo(messages)
    }

    warn(message: any): void {
        console.log(message)
    }
}
