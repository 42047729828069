import React from "react";

interface Props {
  className?: any;
}

export const IdentityAware = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 16V8H32"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M16 8H8V16"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M32 40H40V32"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M8 32V40H16"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <rect
        x="13"
        y="16"
        width="22"
        height="15"
        stroke="white"
        stroke-width="2"
      />
      <rect
        x="17"
        y="21"
        width="5"
        height="5"
        stroke="white"
        stroke-width="2"
      />
      <line x1="25" y1="20" x2="32" y2="20" stroke="white" stroke-width="2" />
      <line x1="25" y1="23" x2="32" y2="23" stroke="white" stroke-width="2" />
      <line x1="25" y1="26" x2="32" y2="26" stroke="white" stroke-width="2" />
    </svg>
  );
};
