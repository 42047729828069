import React from "react";

interface Props {
  className?: any;
}

export const RuleSimulator = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.5L6.86719 16.4245L11.7917 15.5573"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M7.71094 15.224C10.8359 9.4349 16.9583 5.5 24 5.5C34.2161 5.5 42.5 13.7839 42.5 24C42.5 34.2161 34.2161 42.5 24 42.5C13.7839 42.5 5.5 34.2161 5.5 24C5.5 23.8411 5.5026 23.6823 5.50521 23.526"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5 15.5H18.5V31.5H30.5V17.5L32 19V31.5V33H30.5H18.5H17V31.5V15.5V14H18.5H27L28.5 15.5Z"
        fill="white"
      />
      <path
        d="M28 16.4142L29.5858 18L28 18L28 16.4142Z"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M27.5 16.9142L29.0858 18.5L27.5 18.5L27.5 16.9142Z"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="21"
        y1="25.25"
        x2="28"
        y2="25.25"
        stroke="white"
        stroke-width="1.5"
      />
      <line
        x1="21"
        y1="22.25"
        x2="28"
        y2="22.25"
        stroke="white"
        stroke-width="1.5"
      />
      <line
        x1="21"
        y1="28.25"
        x2="26"
        y2="28.25"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
};
