import React from "react";
interface Props {
  className?: any;
}

export const FailedWarning = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="37"
      height="33"
      viewBox="0 0 37 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8004 32.9429H2.80018C2.08318 32.9429 1.42317 32.5582 1.06617 31.9344C0.710166 31.3106 0.712166 30.5432 1.07317 29.9215L17.0733 1.79648C17.4313 1.17974 18.0893 0.800049 18.8003 0.800049C19.5113 0.800049 20.1693 1.17974 20.5283 1.79648L36.5284 29.9215C36.8894 30.5432 36.8914 31.3106 36.5354 31.9344C36.1794 32.5582 35.5164 32.9429 34.8004 32.9429Z"
        fill="#DF3030"
      />
      <path
        d="M16.873 27.2277C16.873 26.9587 16.9184 26.7127 17.0109 26.4817C17.1016 26.2537 17.2318 26.0557 17.3995 25.8897C17.5644 25.7217 17.7679 25.5907 18.0013 25.4967C18.2365 25.4047 18.5008 25.3557 18.7958 25.3557C19.0909 25.3557 19.357 25.4047 19.5943 25.4967C19.8353 25.5907 20.0388 25.7217 20.2037 25.8897C20.3724 26.0557 20.5026 26.2537 20.5923 26.4817C20.6849 26.7127 20.7302 26.9587 20.7302 27.2277C20.7302 27.4977 20.6849 27.7437 20.5923 27.9677C20.5017 28.1927 20.3715 28.3867 20.2037 28.5557C20.0388 28.7217 19.8353 28.8517 19.5943 28.9477C19.357 29.0397 19.0909 29.0857 18.7958 29.0857C18.4998 29.0857 18.2365 29.0397 18.0013 28.9467C17.7679 28.8507 17.5644 28.7207 17.3995 28.5547C17.2308 28.3857 17.1006 28.1917 17.0109 27.9667C16.9184 27.7427 16.873 27.4967 16.873 27.2277ZM20.2614 22.1194C20.2432 22.6583 19.8011 23.0857 19.2619 23.0857H18.3287C17.7896 23.0857 17.3475 22.6583 17.3293 22.1195L16.991 12.1195C16.9718 11.5543 17.4248 11.0857 17.9904 11.0857H19.5994C20.1649 11.0857 20.6179 11.5542 20.5989 12.1194L20.2614 22.1194Z"
        fill="white"
      />
    </svg>
  );
};
