import React from "react";
import { Summary } from "../Summary";
import { Logo } from "../../assets/icons/Logo";
import { SuccessResult } from "./Success";
import { FailedResult } from "./Failed";
import { Reset } from "../../assets/images";
import "./style.css";

const successObject = {
  s3TestResult:true,
  sqsTestResult:true,
  snsTestResult:true,
  kinesisTestResult:true,
  dynamoTestResult:true,
}

interface IProps {
    handleReRunTests: Function
    testResultObject: any
    errorMessageObject: any
    failedTestCounter: number
}
export const Results :  React.FunctionComponent<IProps> = (props: IProps) => {
  const isFailed = props.failedTestCounter;
  const rectangleBackgroundClassName = isFailed? "resultsFailedBackground" : "resultsSuccessBackground"
  return (
    <div className="resultsContainer">
      <div className="resultsDiv ">
        <div className="resultsOverlap">
          <div className={`resultsRectangle ${rectangleBackgroundClassName}`} />
          <div className="results-navigation-menu">
            <div>
              <a href="/"><Logo className="logo"/></a>
            </div>
            <div className="text-button-wrapper">
              <div className="text-button" onClick={()=> props.handleReRunTests()} onKeyDown={()=> props.handleReRunTests()}>
                <Reset className="reset" />
                <div className="text-wrapper-12">Re-run test</div>
              </div>
            </div>
          </div>
          <div className="frame">
            <p className="p">Thanks for taking the test! Here are your results.</p>
            <p className="looks-like-your">
              {isFailed ? (
                <span className="span">You're at risk of malicious data exfiltration</span>
              ) : (<>              
                  <span className="span">Looks like your cloud security is safe </span>
                  <span className="text-wrapper-2">for now</span>
                </>)
              }
              <span className="span">.</span>
            </p>
          </div>
          {isFailed ? (
            <FailedResult testResultObject={props.testResultObject}failedTestCounter={props.failedTestCounter}/> 
          ) : (
            <SuccessResult testResultObject={successObject} /> 
          )}
          <div className="resultsSummary">
            <Summary testResultObject={props.testResultObject} failedTestCounter={props.failedTestCounter}  errorMessageObject={props.errorMessageObject}/>
          </div>
        </div>
      </div>
    </div>
  )
};
