import React from "react";
interface Props {
  className?: any;
}

export const SuccessCheck = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_685_28782)">
        <path
          d="M36.5 18C36.5 27.9405 28.4405 36 18.5 36C8.5595 36 0.5 27.9405 0.5 18C0.5 8.0595 8.5595 0 18.5 0C28.4405 0 36.5 8.0595 36.5 18Z"
          fill="#0BC196"
        />
        <path
          d="M25.2819 11.1439L14.2322 22.1936L10.6993 18.6607C10.3482 18.3088 9.87158 18.111 9.3745 18.111C8.87742 18.111 8.40076 18.3088 8.04966 18.6607C7.31678 19.3936 7.31678 20.5775 8.04966 21.3103L12.9168 26.1775C13.6497 26.9103 14.8336 26.9103 15.5664 26.1775L27.9503 13.7936C28.6832 13.0607 28.6832 11.8768 27.9503 11.1439C27.2175 10.411 26.0148 10.411 25.2819 11.1439Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_685_28782">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
