import React from "react";

interface Props {
  className?: any;
}

export const NotificationsAndAlerts = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5 32C30.8437 32 29.5 30.6563 29.5 29V15.9375C29.5 10.1875 25.0703 5.17969 19.3203 5.00782C13.375 4.82813 8.5 9.59376 8.5 15.5V29C8.5 30.6563 7.15625 32 5.5 32M4 32H34M19 32C17.3437 32 16 33.3438 16 35C16 36.6563 17.3437 38 19 38C20.6563 38 22 36.6563 22 35C22 33.3438 20.6563 32 19 32Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M19 0.5C17.3438 0.5 16 1.84375 16 3.5C16 5.15625 17.3438 6.5 19 6.5C20.6562 6.5 22 5.15625 22 3.5C22 1.84375 20.6562 0.5 19 0.5Z"
        fill="white"
      />
      <path
        d="M35.3438 24.5C37.5234 19.75 37.5234 14.25 35.3516 9.5"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M2.64746 9.5C0.475587 14.25 0.475587 19.75 2.65527 24.5"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
