import React, { useEffect, useState } from "react";
import {
  AwsTestHandler,
  EventType,
  TestEvent,
} from "./handlers/aws-test-handler";
import { LOG_MODE, LogHandler } from "./handlers/log-handler";
import { Observable } from "rxjs";
import { Home } from "./pages/Home";
import { Results } from "./pages/Results";
import { Loader } from "./components/Loader";

const App = () => {
  const logger = new LogHandler(LOG_MODE.OUTPUT);
  const awsTestHandler = new AwsTestHandler(logger);
  const awsTestCount = Object.getOwnPropertyNames(awsTestHandler).filter(
    (word) => word.endsWith("Test")
  ).length;

  const observable = new Observable((subscriber) => {
    awsTestHandler.setSubscriber(subscriber);
  });

  const [s3TestResult, setS3TestResult] = useState(false);
  const [sqsTestResult, setSqsTestResult] = useState(false);
  const [snsTestResult, setSnsTestResult] = useState(false);
  const [kinesisTestResult, setKinesisTestResult] = useState(false);
  const [dynamoTestResult, setDynamoTestResult] = useState(false);
  const [failedTestCounter, setFailedTestCounter] = useState(0);
  const [testsCompleted, setTestsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [s3TestResultObject, setS3TestResultObject] = useState<any>([]);
  const [sqsTestResultObject, setSqsTestResultObject] = useState<any>([]);
  const [snsTestResultObject, setSnsTestResultObject] = useState<any>([]);
  const [kinesisTestResultObject, setKinesisTestResultObject] = useState<any>(
    []
  );
  const [dynamoTestResultObject, setDynamoTestResultObject] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleCounterAndReset = (
    eventSuccess: boolean,
    delayCounter: number
  ) => {
    const counter = awsTestHandler
      .incrementRanTestCounter()
      .getRanTestsCounter();
    if (counter === awsTestCount) {
      setTestsCompleted(true);
      setIsLoading(false);
    }
    if (!eventSuccess) {
      const failedCounter = awsTestHandler
        .incrementFailedTestCounter()
        .getFailedTestCounter();
      setFailedTestCounter(failedCounter);
    }

    // all tests ran and nothing failed
    if (
      counter === awsTestCount &&
      awsTestHandler.getFailedTestCounter() === 0
    ) {
      setFailedTestCounter(0);
    }
  };

  const s3TestOnComplete = (testEvent: TestEvent) => {
    setS3TestResult(testEvent?.eventPayload?.success);
    setS3TestResultObject([testEvent?.eventPayload]);
    handleCounterAndReset(testEvent?.eventPayload?.success, 60);
  };

  const sqsTestOnComplete = (testEvent: TestEvent) => {
    setSqsTestResult(testEvent?.eventPayload?.success);
    setSqsTestResultObject([testEvent?.eventPayload]);
    handleCounterAndReset(testEvent?.eventPayload?.success, 100);
  };

  const snsTestOnComplete = (testEvent: TestEvent) => {
    setSnsTestResult(testEvent?.eventPayload?.success);
    setSnsTestResultObject([testEvent?.eventPayload]);
    handleCounterAndReset(testEvent?.eventPayload?.success, 150);
  };

  const kinesisTestOnComplete = (testEvent: TestEvent) => {
    setKinesisTestResult(testEvent?.eventPayload?.success);
    setKinesisTestResultObject([testEvent?.eventPayload]);
    handleCounterAndReset(testEvent?.eventPayload?.success, 150);
  };

  const dynamoDBTestOnComplete = (testEvent: TestEvent) => {
    setDynamoTestResult(testEvent?.eventPayload?.success);
    setDynamoTestResultObject([testEvent?.eventPayload]);
    handleCounterAndReset(testEvent?.eventPayload?.success, 250);
  };
  
  const getTokenAndRunTests = () => {
    setIsLoading(true);
    awsTestHandler.getToken().then(res => {
      if(res.success) {
        setIsError(false)
        awsTestHandler.initializeTestData();
        awsTestHandler.runAllTests(res.data);
      } else {      
        setIsLoading(false);  
        setIsError(true)
      }
    })
  };

  const handleTestEvent = (testEvent: TestEvent) => {
    switch (testEvent?.eventType) {
      case EventType.EVENT_TYPE_S3:
        s3TestOnComplete(testEvent);
        break;
      case EventType.EVENT_TYPE_SQS:
        sqsTestOnComplete(testEvent);
        break;
      case EventType.EVENT_TYPE_SNS:
        snsTestOnComplete(testEvent);
        break;
      case EventType.EVENT_TYPE_KINESIS:
        kinesisTestOnComplete(testEvent);
        break;
      case EventType.EVENT_TYPE_DYNAMO:
        dynamoDBTestOnComplete(testEvent);
        break;
      default:
        console.error("Unknown Event");
    }
  };

  const subscription = observable.subscribe({
    next(event) {
      const testEvent: TestEvent = JSON.parse(JSON.stringify(event));
      handleTestEvent(testEvent);
    },
    error(err) {
      console.error("something wrong occurred: " + err);
      console.log("Errr is ", err);
    },
    complete() {
      console.log("done");
      console.log("Errr is ", "Domne");
    },
  });

  const cleanupSubscription = () => {
    if (!subscription?.closed) {
      subscription?.unsubscribe();
    }
  };

  useEffect(() => {
    localStorage.removeItem("resultsLog");
    return cleanupSubscription;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReRunTests = () => {
    setS3TestResult(false);
    setSqsTestResult(true);
    setSnsTestResult(false);
    setKinesisTestResult(false);
    setDynamoTestResult(false);
    setFailedTestCounter(0);
    awsTestHandler.resetFailedTestCounter();
    getTokenAndRunTests();
  };

  const testResultObject = {
    s3TestResult,
    sqsTestResult,
    snsTestResult,
    kinesisTestResult,
    dynamoTestResult,
  };
  if (isLoading) {
    return <Loader />;
  }
  const mergeObject = [
    ...s3TestResultObject,
    ...sqsTestResultObject,
    ...snsTestResultObject,
    ...kinesisTestResultObject,
    ...dynamoTestResultObject,
  ];

  return (
    <>      
      {!testsCompleted ? (
        <Home runTests={getTokenAndRunTests} isError={isError}/>
      ) : (
        <Results
          handleReRunTests={handleReRunTests}
          testResultObject={testResultObject}
          errorMessageObject={mergeObject}
          failedTestCounter={failedTestCounter}
        />
      )}
    </>
  );
};

export default App;
