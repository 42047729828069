import React from "react";
import { LoadingSpinner } from "../../assets/icons/LoadingSpinner";
import "./style.css";

export const Loader = (): JSX.Element => {
  return (
    <div className="indexLoader">
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
            <LoadingSpinner className="loading-spinner" color="url(#paint0_angular_211_449)" />
            <div className="loading-spinner spin spinner">
              {/* <img src="./spin1.svg" />
              <img src="./spin2.svg" />
              <img src="./spin3.svg" /> */}
            </div>
          <p className="checking-to-see-if">Checking to see if you&#39;re at risk of data exfiltration</p>
        </div>
      </div>
    </div>
  );
};
