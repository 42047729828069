import React from "react";

interface Props {
  className: any;
}

export const Logo = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`logo-1 ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 114 30"
      width="114"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M15.4143 18.1345L26.474 29.4302H17.2456L6.60406 18.1345" fill="#A6AEAD" />
      <path className="path" d="M26.4741 8.59229H32.934V29.4301H26.4741V8.59229Z" fill="white" />
      <path className="path" d="M15.4144 18.1344L26.4741 8.5923V0L15.4144 9.55675V18.1344Z" fill="#C41DFF" />
      <path
        className="path"
        d="M49.7038 8.59229H56.8126L48.0744 29.4739H42.3787L33.6406 8.59229H40.7493L45.2482 21.0278L49.7038 8.59229Z"
        fill="white"
      />
      <path
        className="path"
        d="M76.2788 20.9694H61.1817C61.8017 22.986 63.5176 24.228 65.9977 24.228C67.9444 24.228 69.9198 23.3367 71.2032 22.08L74.5629 26.3761C72.5297 28.6119 68.997 30.0001 65.5652 30.0001C59.0332 30.0001 54.4478 25.4409 54.4478 19.0844C54.4478 12.2894 59.6099 8.03711 65.5219 8.03711C71.4339 8.03711 76.3797 12.2456 76.3797 18.5875C76.3653 19.1866 76.3221 19.9465 76.2788 20.9694ZM69.8766 17.0824C69.7901 15.022 67.9732 13.6338 65.6373 13.6338C63.6906 13.6338 61.8017 14.569 61.1384 17.0824H69.8766Z"
        fill="white"
      />
      <path
        className="path"
        d="M107.655 8.4315H114V29.3131H107.655L107.468 26.6682C106.055 28.641 103.546 29.8392 100.936 29.8392C95.1538 29.8392 91.0875 25.4115 91.0875 18.8796C91.0875 12.3039 95.1538 7.87622 101.023 7.87622C103.531 7.87622 106.055 9.0014 107.468 10.9157L107.655 8.4315ZM107.252 18.8942C107.252 16.1617 105.132 14.0136 102.479 14.0136C99.6959 14.0136 97.7637 16.2055 97.7637 18.8942C97.7637 21.4369 99.7103 23.7311 102.537 23.7311C105.175 23.7165 107.252 21.4369 107.252 18.8942Z"
        fill="white"
      />
      <path
        className="path"
        d="M89.0832 14.5542C89.8907 14.5542 90.5829 14.6126 91.0875 14.7149V8.05151C87.8864 8.10996 85.5505 9.60046 84.1374 12.4792L83.8346 13.0783L83.2722 8.57757H77.3602V29.4738H83.9643V20.297C83.9643 14.5542 87.425 14.5542 89.0832 14.5542Z"
        fill="white"
      />
      <path className="path" d="M0 5.71359V29.4301H0.0720972H6.532H6.6041V0L0 5.71359Z" fill="white" />
    </svg>
  );
};
