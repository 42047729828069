import React from "react";
import { ContactUs } from "../../components/ContactUs";
import { CloudSecurityDetails } from "../../components/CloudSecurityDetails";
import { Footer } from "../../components/Footer";
import { getResultsBreakdown } from "../../utils";
import "./style.css";

interface IProps {
  errorMessageObjectFunction?: Function;
  errorMessageObject: any;
  testResultObject: any;
  failedTestCounter?: Number;
}

export const Summary: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <div className="summary">
      {props?.failedTestCounter ? (
        <>
          <div className="failedContainer-frame-3">
            <div className="div-wrapper">
              <div className="failedContainer-text-wrapper-3">
                Result Breakdown
              </div>
            </div>
            {/* <div className="failedContainer-links">
                <SecondaryButton className="design-component-instance-node" text="Share results via email" />
                <div className="primary-button-2">
                    <img
                    className="image-small"
                    alt="Link"
                    src=""
                    />
                    <div className="failedContainer-text-wrapper-4">Share results via link</div>
                </div>
              </div> */}
          </div>
          <div className="failedContainer-frame-4">
            {getResultsBreakdown(props.errorMessageObject)}
          </div>
        </>
      ) : null}
      <div className="summary-3"  id="how-to-strengthen-your-cloud-security">
        <p className="summary-text-wrapper-3">
          How Kivera can strengthen your cloud security and prevent data exfiltration
        </p>
        <p className="summary-text-wrapper-4">
          Kivera enables full control over the movement of data in the cloud,
          giving you the ability to enforce data perimeters that stop data from
          leaving your organization’s boundaries
        </p>
      </div>
      <CloudSecurityDetails />
      <ContactUs />
      <Footer />
    </div>
  );
};
