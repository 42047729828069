import React from "react";

interface Props {
  className?: any;
}

export const ResourceTagging = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5 7C28.5 7.82812 27.8281 8.5 27 8.5C26.1719 8.5 25.5 7.82812 25.5 7C25.5 6.17188 26.1719 5.5 27 5.5C27.8281 5.5 28.5 6.17188 28.5 7Z"
        fill="white"
      />
      <path
        d="M40.5 28C40.5 33.7969 35.7969 38.5 30 38.5C24.2031 38.5 19.5 33.7969 19.5 28C19.5 22.2031 24.2031 17.5 30 17.5C35.7969 17.5 40.5 22.2031 40.5 28Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M30.8203 17.5391L33 15.3828V1H18.6172L1.5 18.2578L15.7422 32.5L19.5391 28.7344"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M30 34V22"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M24 28H36"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
