import React from "react";
interface Props {
  className?: any;
}

export const Reset = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.25H18.25V1"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M19 10C19 14.9701 14.9701 19 10 19C5.02995 19 1 14.9701 1 10C1 5.02995 5.02995 1 10 1C13.6328 1 16.763 3.15365 18.1849 6.2526"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
