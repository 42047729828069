import React from "react";

interface Props {
  className?: any;
}

export const PolicyCode = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1H34.5V31H1.5V1Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M1.5 7H34.5"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M24 13L28.5 19L24 25"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M12 13L7.5 19L12 25"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path d="M21 11.5H18L15 26.5H18L21 11.5Z" fill="white" />
    </svg>
  );
};
