import React from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import "./style.css";

export const ContactUs = (): JSX.Element => {
  return (
    <div className={`CTA-banner-results`}>
      <div className="CTA-banner-results-frame">
        <div className="CTA-banner-results-div">Get more out of your security with Kivera today.</div>
        <PrimaryButton className="primary-button-instance" inverse={false} stateProp="default" text="Request Demo" onClick={() => window.open('https://www.kivera.io/request-demo','_blank')}/>
      </div>
    </div>
  );
};
