import React from "react";

interface Props {
  className: any;
}

export const IconArrow1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-arrow ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12.8535 17.8536C12.6583 18.0488 12.3417 18.0488 12.1464 17.8536L8.96446 14.6716C8.7692 14.4763 8.7692 14.1597 8.96446 13.9645C9.15972 13.7692 9.47631 13.7692 9.67157 13.9645L12 16.2929L12 7.5C12 7.22386 12.2239 7 12.5 7C12.7761 7 13 7.22386 13 7.5L13 16.2929L15.3284 13.9645C15.5237 13.7692 15.8403 13.7692 16.0355 13.9645C16.2308 14.1597 16.2308 14.4763 16.0355 14.6716L12.8535 17.8536Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
