import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const LoadingSpinner = ({ color = "url(#paint0_angular_211_449)", className }: Props): JSX.Element => {
  return (
    <>
    <svg
      className={`loading-spinner ${className}`}
      fill="none"
      height="245"
      viewBox="0 0 245 245"
      width="245"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle" cx="122.5" cy="122.5" r="90.5" stroke={color} strokeWidth="2" />
      <path className="path" d="M128.066 129L152 154H132.029L109 129" fill="#A6AEAD" />
      <path className="path" d="M128 129L152 108.479V90L128 110.553V129Z" fill="#C41DFF" />
      <path className="path" d="M94 102.425V154H94.1638H108.836H109V90L94 102.425Z" fill="white" />
      {/* <defs className="defs">
        <radialGradient
          className="radial-gradient"
          cx="0"
          cy="0"
          gradientTransform="translate(122.5 122.5) rotate(90) scale(91.5)"
          gradientUnits="userSpaceOnUse"
          id="paint0_angular_212_981"
          r="1"
        >
          <stop className="stop" stopColor="white" stopOpacity="0.1" />
          <stop className="stop" offset="1" stopColor="white" />
        </radialGradient>
      </defs> */}
    </svg>
    {/* <svg width="245" height="245" viewBox="0 0 245 245" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="122.5" cy="122.5" r="90.5" stroke="url(#paint0_angular_212_981)" stroke-width="2"/>
      <path d="M128.066 129L152 154H132.029L109 129" fill="#A6AEAD"/>
      <path d="M128 129L152 108.479V90L128 110.553V129Z" fill="#C41DFF"/>
      <path d="M94 102.425V154H94.1638H108.836H109V90L94 102.425Z" fill="white"/>
      <defs>
        <radialGradient id="paint0_angular_212_981" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(122.5 122.5) rotate(90) scale(91.5)">
          <stop stop-color="white" stop-opacity="0.1"/>
          <stop offset="1" stop-color="white"/>
        </radialGradient>
      </defs>
    </svg> */}
    </>
  );
};

LoadingSpinner.propTypes = {
  color: PropTypes.string,
};
