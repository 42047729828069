import React from "react";
import { IconArrow6 } from "../../assets/icons/IconArrow6";
import {getPreventativeControls} from "../../utils"

interface IProps {
    testResultObject: any
    failedTestCounter: number
}

export const FailedResult :  React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <div className="failedContainer">
      <p className="text-wrapper-7">{props?.failedTestCounter || 0} of 5 preventative controls failed</p>
      <div className="frame-9">
        {getPreventativeControls(props.testResultObject)}
      </div>
      <div className="primary-button-2">
        <div className="text-wrapper-11" >        
          <a href="#how-to-strengthen-your-cloud-security" className="anchor-tag-class">
            <span>Strengthen your cloud security</span>
            <IconArrow6 className="reset" />
          </a>
        </div>
      </div>
    </div>
  );
};
