import React from "react";

interface Props {
  className?: any;
}

export const LinkedIn = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.96875" width="31.9994" height="32" rx="8" fill="white" />
      <path
        d="M12.5505 24H9.23332V13.3177H12.5505V24ZM10.8899 11.8606C9.82932 11.8606 8.96875 10.9817 8.96875 9.92114C8.96875 8.86057 9.82875 8 10.8899 8C11.951 8 12.811 8.86 12.811 9.92114C12.811 10.9817 11.9505 11.8606 10.8899 11.8606ZM24.9653 24H21.6556V18.8C21.6556 17.5606 21.6305 15.9714 19.931 15.9714C18.2065 15.9714 17.9419 17.3177 17.9419 18.7109V24H14.6282V13.3177H17.8093V14.7749H17.8556C18.2985 13.9354 19.3802 13.0497 20.9939 13.0497C24.351 13.0497 24.9682 15.2606 24.9682 18.132V24H24.9653Z"
        fill="black"
      />
    </svg>
  );
};
