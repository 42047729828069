import React from "react";

interface Props {
  className?: any;
}

export const FullAuditLog = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="39"
      viewBox="0 0 30 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5H1.5V37.5H28.5V4.5H18"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18 4.5C18 2.84375 16.6563 1.5 15 1.5C13.3437 1.5 12 2.84375 12 4.5V10.5H18V4.5Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M9 24L13.5 28.5L22.5 19.5"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
