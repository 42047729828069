import React from "react";
import { SuccessCheck, FailedWarning } from "../assets/images";
interface IProps {
  s3TestResult: boolean;
  sqsTestResult: boolean;
  snsTestResult: boolean;
  kinesisTestResult: boolean;
  dynamoTestResult: boolean;
}

export const PreventativeControlsListDescription = {
  S3 : {    
    description: "uploaded to an external S3 Bucket",
  },
  SQS : {    
    description: "pushed to an external SQS Queue",
  },
  SNS : {    
    description: "published to an external SNS Topic",
  },
  KINESIS : {    
    description: "pushed to an external Kinesis",
  },
  DYNAMO : {    
    description: "written to an external DynamoDB",
  },
}

export const PreventativeControlsList = [
  {
    name: "S3 Bucket",
    description: "uploaded to an external S3 Bucket",
    type: "S3_Bucket",
    id: "s3TestResult",
  },
  {
    name: "SQS Queue",
    description: "pushed to an external SQS Queue",
    type: "SQS_Queue",
    id: "sqsTestResult",
  },
  {
    name: "SNS Topic",
    description: "published to an external SNS Topic",
    type: "SNS_Topic",
    id: "snsTestResult",
  },
  {
    name: " Kinesis",
    description: "pushed to an external Kinesis",
    type: " Kinesis",
    id: "kinesisTestResult",
  },
  {
    name: "DynamoDB",
    description: "written to an external DynamoDB",
    type: "DynamoDB",
    id: "dynamoTestResult",
  },
];

export const getPreventativeControls = (testResultObject: IProps) => {
  return PreventativeControlsList.map((item) => {
    const { id, description } = item;
    const displaySuccessImage = testResultObject[id as keyof IProps] || false;
    return (
      <div className="frame-10" key={id}>
        <div className="frame-11">
          <div className="colored-checked">
            <div className={displaySuccessImage ? "group" : ""}>
              {displaySuccessImage ? (
                <SuccessCheck className="icon-action-check" />
              ) : (
                <FailedWarning className="icon-action-error" />
              )}
            </div>
          </div>
          <p className="text-wrapper-9"><span className="text-wrapper-11 control-header-top">Control: </span><span>Prevent data from being {description}</span></p>
        </div>
        <div className={`frame-12 ${displaySuccessImage ? "success-border" : "failed-border"}`}>
          <div className="text-wrapper-10">
            {displaySuccessImage ? "Passed" : "Failed"}
          </div>
        </div>
      </div>
    );
  });
};
export const getResultsBreakdown = (errorMessageObject: any) => {
  const getResultsLog = localStorage.getItem("resultsLog");
  const itemsList = getResultsLog ? JSON.parse(getResultsLog) : null;
  return errorMessageObject.filter((msgObj:any) => !msgObj.success).map((item: any, index:any) => {
    const { description,eventType, data } = item;
    const dataMessage =
      itemsList?.find((itemMsg: any) =>
        itemMsg?.data?.includes(eventType.toLowerCase())
      ) || [];
    return (
      <div className="failedContainer-frame-5" key={index}>
        <div className="failedContainer-frame-6">
          <FailedWarning className="icon-action-error" />
          <div className="failedContainer-frame-8">
            <div className="failedContainer-frame-9">
              <p className="failedContainer-text-wrapper-6"><span style={{fontWeight: '600'}}>Control: </span>Prevent data from being {description}</p>
            </div>
            <div className="failedContainer-frame-10">
              <div className="failedContainer-text-wrapper-7">Failed</div>
            </div>
          </div>
        </div>
        <div className="failedContainer-frame-11">
          <div className="failedContainer-result-header">Request</div>
          <div className="failedContainer-text-wrapper-8">
            {dataMessage?.data || null}
          </div>
          <div className="failedContainer-result-header">Response</div>
          <div className="failedContainer-text-wrapper-8">{eventType.toLowerCase() === "s3" ? data : JSON.stringify(data)}</div>
        </div>
      </div>
    );
  });
};
