import React from "react";

interface Props {
  className?: any;
}
export const Youtube = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.96875" width="32" height="32" rx="7.11111" fill="white" />
      <path
        d="M27.1426 10.75C26.9434 9.65039 25.9941 8.84961 24.8926 8.59961C23.2441 8.25 20.1934 8 16.8926 8C13.5937 8 10.4941 8.25 8.84375 8.59961C7.74414 8.84961 6.79297 9.59961 6.59375 10.75C6.39258 12 6.19336 13.75 6.19336 16C6.19336 18.25 6.39258 20 6.64258 21.25C6.84375 22.3496 7.79297 23.1504 8.89258 23.4004C10.6426 23.75 13.6426 24 16.9434 24C20.2441 24 23.2441 23.75 24.9941 23.4004C26.0938 23.1504 27.043 22.4004 27.2441 21.25C27.4434 20 27.6934 18.1992 27.7441 16C27.6426 13.75 27.3926 12 27.1426 10.75ZM14.1934 19.5V12.5L20.293 16L14.1934 19.5Z"
        fill="black"
      />
    </svg>
  );
};
