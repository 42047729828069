import React from "react";

interface Props {
  className: any;
}

export const IconArrow6 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-arrow-6 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12.8532 17.3536C12.658 17.5488 12.3414 17.5488 12.1461 17.3536L8.96413 14.1716C8.76886 13.9763 8.76886 13.6597 8.96413 13.4645C9.15939 13.2692 9.47597 13.2692 9.67123 13.4645L11.9997 15.7929L11.9997 7C11.9997 6.72386 12.2235 6.5 12.4997 6.5C12.7758 6.5 12.9997 6.72386 12.9997 7L12.9997 15.7929L15.3281 13.4645C15.5233 13.2692 15.8399 13.2692 16.0352 13.4645C16.2305 13.6597 16.2305 13.9763 16.0352 14.1716L12.8532 17.3536Z"
        fill="#0E0119"
        fillRule="evenodd"
      />
    </svg>
  );
};
