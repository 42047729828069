import React from "react";
import { CtaBanner } from "../../components/CtaBanner";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Footer } from "../../components/Footer";
import { IconArrow1 } from "../../assets/icons/IconArrow1";
import { Logo } from "../../assets/icons/Logo";
import "./style.css";

interface IProps {
    runTests: Function
    isError: boolean
}

export const Home: React.FunctionComponent<IProps> = (props: IProps) => {

  const handleClick = () => {
      props.runTests()
  }

  return (
    <div className="homeIndex">
      <div className="container">
        {props.isError && (
          <div className="errorInfo"> Network Error : Please try again later</div>
        )}
        <div className="overlap-group">
          <div className="rectangle" />
          <Logo className="logo" />
          <div className="top-content">
            <p className="text-heading-2">Are you at risk of data exfiltration in the cloud?</p>
            <div className="run-test-button-container">
              <div className="primary-button-wrapper">
                <PrimaryButton
                    className="design-component-instance-node"
                    inverse={false}
                    stateProp="default"
                    text="Run a 5 second test now"
                    onClick={handleClick}
                  />
              </div>
            </div>
          </div>
          <div className="group">
            <div className="text-button-text text-normal">
              <a href="#how-it-works" className="how-it-works-hash"><span>Learn how it works</span>
              <IconArrow1 className="icon-arrow-instance" /></a>
            </div>          
          </div>
          <div className="feature-image">
            <video width="100%" height="100%" autoPlay loop muted playsInline>
              <source src="Kivera_Data_Exfiltration_Animation.mp4" type="video/mp4" />
            </video>
            <div className="parent-middle-container">
              <div className="middle-container">
                <div className="left-content">
                  <p className="text-heading-3" id="how-it-works">How does this test work?</p>
                  <p className="text-body-normal content-text">
                    Our tool will demonstrate how an insider or malicious actor may
                    take sensitive data from your network, and exfiltrate it through
                    cloud services. Kivera will assume an AWS role and then push a set
                    of sample data from your browser session to several cloud
                    services.
                  </p>
                  <div className="button-container">
                    <PrimaryButton
                      className="design-component-instance-node"
                      inverse={false}
                      stateProp="default"
                      text="Run test"
                      onClick={handleClick}
                    />
                    <p className="text-details">Get results within 5 seconds</p>
                  </div>
                </div>
                <div className="right-content">
                  <p className="text-sub-heading-1">
                    The test will identify if your data is at risk of being...
                  </p>
                  <div className="text-wrapper-list">
                    <div className="ellipse" />
                    <p className="text-wrapper-list-body">
                      uploaded to an external S3 Bucket
                    </p>
                  </div>
                  <div className="text-wrapper-list">
                    <div className="ellipse" />
                    <p className="text-wrapper-list-body">
                      pushed to an external SQS Queue
                    </p>
                  </div>
                  <div className="text-wrapper-list">
                    <div className="ellipse" />
                    <p className="text-wrapper-list-body">
                      published to an external SNS Topic
                    </p>
                  </div>
                  <div className="text-wrapper-list">
                    <div className="ellipse" />
                    <p className="text-wrapper-list-body">pushed to an external Kinesis</p>
                  </div>
                  <div className="text-wrapper-list">
                    <div className="ellipse" />
                    <p className="text-wrapper-list-body">
                      written to an external DynamoDB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaBanner className="CTA-banner-instance" />
        <Footer />
      </div>
    </div>
  );
};
