import React from "react";
interface Props {
  className?: any;
}

export const Twitter = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.96875" width="32" height="32" rx="8" fill="white" />
      <path
        d="M26.7742 9.90634C26.0501 10.2238 25.2751 10.4421 24.4581 10.5412C25.2917 10.0411 25.929 9.24998 26.2328 8.30757C25.451 8.76278 24.5861 9.0925 23.6664 9.27889C22.9332 8.49396 21.885 8 20.7217 8C18.4918 8 16.6845 9.80669 16.6845 12.0335C16.6845 12.354 16.7233 12.6616 16.7903 12.9532C13.4359 12.7963 10.4623 11.1846 8.47351 8.74987C8.12411 9.34287 7.92542 10.0318 7.92542 10.7817C7.92542 12.1849 8.6396 13.4151 9.71919 14.1386C9.05913 14.1195 8.43783 13.9368 7.89343 13.636V13.6846C7.89343 15.6414 9.28366 17.2727 11.1291 17.6443C10.7926 17.7335 10.4334 17.7852 10.0686 17.7852C9.80961 17.7852 9.56232 17.7599 9.31565 17.7144C9.83484 19.3163 11.321 20.4826 13.0927 20.5176C11.7153 21.5972 9.96955 22.2407 8.08843 22.2407C7.76794 22.2407 7.44806 22.2253 7.12695 22.1865C8.92134 23.3307 11.0393 24 13.327 24C20.75 24 24.8063 17.8516 24.8063 12.5269C24.8063 12.3571 24.8063 12.1842 24.7934 12.0108C25.5851 11.4467 26.2704 10.7319 26.8117 9.92172L26.7742 9.90634Z"
        fill="black"
      />
    </svg>
  );
};
