import React from "react";
import { useReducer } from "react";
import { IconArrow5 } from "../../assets/icons/IconArrow5";
import "./style.css";

interface Props {
  stateProp: "hover" | "default";
  inverse: boolean;
  className: any;
  text?: string;
  onClick?: any;
}

export const PrimaryButton = ({ stateProp, inverse, className, text = "Request Demo", onClick }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
    inverse: inverse,
  });

  return (
    <div
      className={`primary-button inverse-${state.inverse} ${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={onClick}
    >
      <div className="request-demo">{text}</div>
      <IconArrow5 className="icon-arrow" color={state.inverse ? "white" : "#0E0119"} />
    </div>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}