import React from "react";
import {
  EnforcementMode,
  PolicyLanguage,
  PolicyCode,
  FullAuditLog,
  RuleSimulator,
  IdentityAware,
  ResourceTagging,
  NotificationsAndAlerts,
} from "../../assets/images";

export const CloudSecurityDetails = (): JSX.Element => {
  return (
    <div className="summary-wrapper">
      <div className="div-wrapper">
        <div className="summary-4">
          <div className="summary-5">
            <div className="summary-6">
              <EnforcementMode className="image-medium" />
              <div className="summary-text-wrapper-5">Enforcement Modes</div>
              <p className="summary-text-wrapper-6">
                Set your enforcement mode to allowlist, blocklist or leverage
                learning mode to monitor evaluation results.
              </p>
            </div>
            <div className="summary-6">
              <PolicyLanguage className="image-medium" />
              <div className="summary-text-wrapper-5">Policy Language</div>
              <p className="summary-text-wrapper-6">
                Kivera leverages Rego (see: OPA) to control your cloud down to
                the individual parameter.
              </p>
            </div>
            <div className="summary-6">
              <PolicyCode className="image-medium" />
              <div className="summary-text-wrapper-5">Policy as Code</div>
              <p className="summary-text-wrapper-6">
                API first platform to allow for programmatic updates to your
                Kivera configuration. Leverage Kivera Konfig to manage your
                policy as code in YAML or JSON.
              </p>
            </div>
            <div className="summary-6">
              <FullAuditLog className="image-medium" />
              <div className="summary-text-wrapper-5">Full Audit Log</div>
              <p className="summary-text-wrapper-6">
                Kivera provides a immutable audit log of actions taken in the
                Kivera console for visibility, traceability and compliance.
              </p>
            </div>
          </div>
          <div className="summary-5">
            <div className="summary-6">
              <RuleSimulator className="image-medium" />
              <div className="summary-text-wrapper-5">Rule Simulator</div>
              <p className="summary-text-wrapper-6">
                Mock historic traffic against rules in Kivera to ensure you’re
                getting the expected result.
              </p>
            </div>
            <div className="summary-6">
              <IdentityAware className="image-medium" />
              <div className="summary-text-wrapper-5">Identity-Aware</div>
              <p className="summary-text-wrapper-6">
                Not all workloads are created equally, especially when it
                comes to risk. Use Identifiers to ensure the right workloads
                get the right guardrails based on their risk.
              </p>
            </div>
            <div className="summary-6">
              <ResourceTagging className="image-medium" />
              <div className="summary-text-wrapper-5">Resource Tagging</div>
              <p className="summary-text-wrapper-6">
                Custom tags can be applied at every level of the Kivera
                hierarchy allowing you to add your own organisational metadata
                to Kivera.
              </p>
            </div>
            <div className="summary-6">
              <NotificationsAndAlerts className="image-medium" />
              <div className="summary-text-wrapper-5">
                Notifications and Alerts
              </div>
              <p className="summary-text-wrapper-6">
                Kivera supports multiple integrations for logs and
                notifications including Splunk, ELK, BigQuery, Slack, Webhooks
                and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
